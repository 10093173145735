html,
body {
  font-size: 1em;
}

.App {
  width: 70%;
  max-width: 800px;
  margin: 10vh auto;
}

.authForm {
  margin: 3vh auto;
}

.importantText {
  font-size: larger;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  padding: auto;
  margin-left: 1em;
  margin-right: 1em;
}

td {
  padding-left: auto;
  padding-right: 1em;
  margin-left: 1em;
  margin-right: 1em;
}
/* REMOVES INPUT TYPE=NUMBER ARROWS */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* END -- REMOVES INPUT TYPE=NUMBER ARROWS */
